<template>
   <v-form ref="banner-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Banner management</h3>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <div class="d-md-flex">
                     <div class="align-self-start">
                        <v-img ref="imgPreview" class="img-preview"
                           :src="this.$getMediaUrl(data.banner)"
                           :width="isMobile ? '320' : '500'"
                           :height="isMobile ? '320' : '500'"
                           contain />
                     </div>
                     <div class="align-self-start">
                        <v-row>
                           <v-col cols="12">
                              <ImageBtnUpload v-model="data.banner" :showInput="false" :showView="false" @onUploaded="onUploaded"/>
                           </v-col>
                           <!-- <v-col cols="12">
                              <FormDeleteBtn :removeAction="removeItem" v-if="data.banner" />
                           </v-col> -->
                        </v-row>
                     </div>
                  </div>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import ImageBtnUpload from '@/components/form/ImageBtnUpload'
// import FormDeleteBtn from '@/components/form/FormDeleteBtn'
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
   name: 'BannerManagement',
   components: {
      ImageBtnUpload,
      // FormDeleteBtn,
   },
   data() {
      return {
         isNew: true,
         data: {
            id: null,
            banner: '',
         },
      }
   },
   computed: {
      isMobile() {
         return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      }
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),

      async initData() {
         const resp = await this.sendRequest({ type: 'get_banner', data: { page: 0, limit: 1 } })
         if (!resp) return
         const data = _.head(resp.data)
         if (data) {
            this.isNew = false
            this.data = {
               id: data.id,
               banner: data.banner,
            }
         }
      },
      onUploaded() {
         this.formSubmit()
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['banner-form'].validate()
         if (isValid) {
            const action = this.isNew ? this.createItem : this.updateItem
            const resp = await action()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp,
            })
            this.setShowDialog(true)
            if (!!resp && this.isNew) {
               this.isNew = false
               this.data.id = resp.data.id
               this.initData()
            }
         }
      },
      async createItem() {
         console.log('create')
         const formdata = {
            status: 'active',
            data_status: 'active',
            banner: this.data.banner,
         };
         return await this.sendRequest({ type: 'add_banner', data: formdata })
      },
      async updateItem() {
         console.log('update');
         const formdata = {
            data_type: 'banner_data',
            id: this.data.id,
            banner: this.data.banner,
         };
         return await this.sendRequest({ type: 'update_banner', data: formdata });
      },
      // async removeItem(dialog) {
      //    console.log('delete')
      //    dialog.value = false
      //    const resp = await this.sendRequest({ url: '', method: 'DELETE', params: {id: this.id} })
      //    if(resp.data) {
      //       this.setShowDialog(true)
      //       await this.initData()
      //    } else {
      //       this.setDialogMessage({ 
      //          message: 'Failed', 
      //          isError: true,
      //       })
      //       this.setShowDialog(true)
      //    }
      // },
   },
   async mounted() {
      await this.initData()
   }
}
</script>

<style lang='scss' scoped>
.img-preview {   
    background-color: #e0e0e0!important
}
</style>